
.container-content {
    width: 100%;
    max-width: 900px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5px;
    background-color: #f5f5f5;
    /* Set the margin top to 10% of the screen height */
    margin-top: 5%;
}

.content-top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-content-middle {
    width: 100%;
    max-width: 900px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: top;
    flex-direction: row;
    margin: 5px;
}

.container-content-outline {
    width: 100%;
    max-height: 800px;
    max-width: 300px;
    overflow: auto;
    padding: 10px;
    margin: 10px;
    border: thin solid #ccc;
    background-color: white;
}

.label-outline {
    /* Prevent label from wrapping */
    white-space: nowrap;
}

.container-content-generation {
    width: 100%;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: top;
    flex-direction: column;
}

.container-gen {
    margin: 10px;
    padding: 10px;
    border: thin solid #ccc;
    /* Pile vertically and center */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-content {
    width: auto;
    margin: 5px;
    
    height: 30px;
    color: #444444;
    font-size: 1.1em;
    border-radius: 5px;
    border: thin solid #a7a7a7;
    background-color: #f5f5f5;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

}

.container-parts {
    width: 100%;
    max-width: 480px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin: 5px;
    overflow: auto;
}

.input-content {
    width: 100%;
    min-height: 300px;
    resize: vertical;
    margin: 5px;
}

.label-item {
    font-size: 0.9em;
    height: 20px;
    padding: 0;
    margin: 0;
    /* Set the font color to grey */
    color: #444444;
    font-family: 'Roboto', sans-serif;
    /* translate right by 2 levels */
    transform: translate(4em, 0);
    cursor: pointer;
}

.container-part-single {
    width: 100%;
    /* display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: row; */
    /* Don't wrap */
    white-space: nowrap;
    margin: 2px;
}

.button-part-delete {
    display: inline-block;
    width:  20px;
    height: 20px;
    /* border-radius: 5px; */
    border: thin solid #a7a7a7;
    background-color: #f5f5f5;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    margin: 3px;
    padding: 0;
    font-size: 1em;
    color: #444444;

}

.label-part {
    display: inline-block;
    cursor: pointer;
    font-size: 1em;
    color: #444444;
    font-family: 'Roboto', sans-serif;
    margin: 1px;
    padding: 0;
    /* word-wrap: none; */
}

.label-selected {
    color: white;
    background-color: turquoise;
}

.button-waiting {
    cursor: wait;
}

.content-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}