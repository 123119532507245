/* Style corresponding to OutlineView component */

.container-outline {
    width: 100%;
    max-width: 600px;
    height: auto;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 10px;
    /* Set the margin top to 10% of the screen height */
    margin-top: 10%;
}

.outline-title {
    margin: 0;
    padding: 10px;
}

.container-outline-text {
    width: 100%;
    max-width: 550px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin: 5px;
}

.input-outline {
    font-size: 1em;
    min-height: 200px;
    padding-left: 5px;
    /* Set the font color to grey */
    color: #444444;
    font-family: 'Roboto', sans-serif;
    border: thin solid #ccc;
    resize: vertical;
    /* Disable line wrapping for the textarea */
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
}

.container-outline-parsed {
    width: 100%;
    max-width: 550px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin: 5px;
}

.container-outline-labels {
    padding: 5px;
    border: thin solid #ccc;
    min-height: 200px;
    max-height: 500px;
    resize: vertical; 
    overflow: auto;
}

.label-chapter {
    font-size: 1.3em;
    height: 30px;
    padding: 0 0 0 5px;
    margin: 0;
    /* Set the font color to grey */
    color: #444444;
    font-family: 'Roboto', sans-serif;
}

.label-section {
    font-size: 0.9em;
    height: 20px;
    padding: 0;
    margin: 0;
    /* Set the font color to grey */
    color: #444444;
    font-family: 'Roboto', sans-serif;
    /* translate right by 1 level */
    transform: translate(2em, 0);
}

.label-item-outline {
    font-size: 0.9em;
    height: 20px;
    padding: 0;
    margin: 0;
    /* Set the font color to grey */
    color: #444444;
    font-family: 'Roboto', sans-serif;
    /* translate right by 2 levels */
    transform: translate(4em, 0);
}

.outline-bottom {
    /* Space the buttons out */
    width: 100%;
    max-width: 500px;
    padding: 10px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.button-outline {
    width: auto;
    min-width: 100px;
    /* max-width: 100px; */
    height: 35px;
    color: #444444;
    font-size: 1.1em;
    border-radius: 5px;
    border: thin solid #a7a7a7;
    background-color: light grey;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.button-waiting {
    cursor: wait;
}