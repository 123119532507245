.container-form {
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 600px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 10px;
    /* Set the margin top to 10% of the screen height */
    margin-top: 10%;
}

.form-title {
    margin: 0;
    padding: 10px;
}

.form-element {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin: 5px;
}

.input-single {
    font-size: 1.3em;
    height: 30px;
    padding-left: 5px;
    /* Set the font color to grey */
    color: #444444;
    font-family: 'Roboto', sans-serif;
    border: thin solid #ccc;
}

.form-element-bottom {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
    padding: 5px;
}

.input-multiple {
    font-size: 1.2em;
    height: 100px;
    padding: 5px;
    color: #444444;
    font-family: 'Roboto', sans-serif;
    /* Allow the user to resize vertiacally */
    resize: vertical;
    border: thin solid #ccc;

    max-height: 400px;
}

.button-form {
    width: 100%;
    max-width: 100px;
    height: 30px;
    color: #444444;
    font-size: 1.1em;
    border-radius: 5px;
    border: thin solid #ccc;
    background-color: #f5f5f5;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

}